<template>
  <wrapper-page>
    <template v-slot:MainContentHeaderActions>
      <div
        v-if="$auth.user.isAgent"
        class="col-md-8 col-sm-12 text-right hidden-xs"
      >
        <button
          v-if="isProjectCompleted && !project.archived"
          @click="isProjectArchiveOrUnarchiveClick = true"
          class="btn btn-warning mr-2"
          :disabled="!canArchiveProjects || isSubscriptionReadOnly()"
          :title="
            !canArchiveProjects &&
            'You reached the archived projects limit for your workspace.'
          "
        >
          Archive Project
        </button>
        <router-link
          :to="{ path: `/projects/${projectId}/timelines` }"
          class="btn btn-primary ml-2"
        >
          Timelines
        </router-link>
        <router-link
          :to="{ path: `/projects/${projectId}` }"
          class="btn btn-primary ml-2"
          ><i class="icon-equalizer"></i>
          <span>Project Settings</span></router-link
        >
        <router-link
          :to="{ path: `/clients/${project.client_id}` }"
          class="btn btn-primary ml-2"
          ><i class="icon-settings"></i>
          <span>Client Settings</span></router-link
        >
      </div>
    </template>

    <div class="d-flex align-items-center" style="padding-bottom: 1.5rem">
      <h1
        class="d-inline-block mr-2"
        style="margin-bottom: 0; line-height: 1.7rem"
      >
        {{ project.name }}
      </h1>
      <span
        v-if="project.archived"
        :class="[
          'badge',
          'xs',
          project.cancelled ? 'badge-disabled' : 'badge-primary',
        ]"
        >{{ project.cancelled ? "cancelled" : "Archived" }}</span
      >
    </div>

    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card">
          <div class="body">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#previewSharing"
                  :class="activeTab == 'previewSharing' ? `active` : ``"
                  @click="activeTab = 'previewSharing'"
                  >Preview sharing</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#deliverables"
                  :class="activeTab == 'deliverables' ? `active` : ``"
                  @click="activeTab = 'deliverables'"
                  >Deliverables</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#csatfeedback"
                  :class="activeTab == 'csatfeedback' ? `active` : ``"
                  @click="activeTab = 'csatfeedback'"
                  >CSAT Feedback</a
                >
              </li>
            </ul>
            <div class="tab-content mt-0">
              <div
                class="tab-pane"
                id="previewSharing"
                :class="activeTab == 'previewSharing' ? `active` : ``"
              >
                <div class="body">
                  <div style="min-height: 400px">
                    <button
                      @click="addNewDeliverable"
                      type="button"
                      class="btn btn-primary mr-2 custom-plus-button"
                      title="Add New Deliverable"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                    <span><b>Add deliverable</b></span>
                    <div class="mt-4">
                      <table class="custom-table" style="min-height: 100px">
                        <thead>
                          <tr>
                            <th></th>
                            <th class="text-left" style="width: 15rem">
                              Deliverable Name
                            </th>
                            <th class="text-left" style="width: 15rem">
                              Stage
                            </th>
                            <th class="text-left" style="width: 8rem">
                              Move forward
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-for="(
                              multiDeliverable, index
                            ) in multiDeliverables"
                          >
                            <tr :data-id="index" :key="index">
                              <td style="width: 50px">
                                <button
                                  v-if="multiDeliverables?.length > 1"
                                  @click="removeDeliverable(index)"
                                  type="button"
                                  class="btn btn-danger btn-sm"
                                  title="Remove Stage"
                                  style="
                                    margin-right: auto;
                                    margin-bottom: 1rem;
                                  "
                                >
                                  <span class="sr-only">Remove stage</span>
                                  <i class="fa fa-times"></i>
                                </button>
                              </td>
                              <td>
                                <form-input-group
                                  :field="{
                                    type: 'select',
                                    options: workflowOptions,
                                  }"
                                  @input="(wId) => onWorkflowSelect(wId, index)"
                                />
                              </td>
                              <td>
                                <form-input-group
                                  v-if="
                                    stageOptions(multiDeliverable?.workflowId)
                                      ?.length
                                  "
                                  :field="{
                                    type: 'select',
                                    options: stageOptions(
                                      multiDeliverable?.workflowId
                                    ),
                                  }"
                                  @input="(sId) => onStageSelect(sId, index)"
                                />
                              </td>
                              <td>
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    checked=""
                                    v-model="multiDeliverable.moveForward"
                                  />
                                  <span class="slider round"></span>
                                </label>
                              </td>
                              <td>
                                <input
                                  class="form-control"
                                  v-model="multiDeliverable.reviewLink"
                                />
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <div class="row clearfix mt-1">
                        <div
                          class="col-9 mb-2"
                          style="
                            display: flex;
                            flex-direction: row;
                            gap: 1rem;
                            flex-wrap: wrap;
                            align-items: baseline;
                            justify-content: space-between;
                          "
                        >
                          <p class="mb-0">
                            Time allowed for client feedback:
                            <strong
                              >{{
                                project?.days_allowed_for_client_feedback
                              }}
                              business days</strong
                            >
                          </p>
                          <div>
                            <label class="mt-2 mr-1" for="ddlTemplates"
                              >Templates</label
                            >
                            <button
                              id="ddlTemplates"
                              class="btn btn-outline-secondary dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {{ emailTemplate?.title }}
                            </button>
                            <div
                              v-if="emailTemplates.length"
                              class="dropdown-menu"
                            >
                              <a
                                v-for="(template, index) in emailTemplates"
                                :key="template?.title"
                                class="dropdown-item"
                                @click="chosenTemplateIndex = index"
                                >{{ template?.title }}</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-9">
                          <text-editor
                            v-if="emailTemplates?.length"
                            :editorText="emailText"
                            :placeholder="placeholder"
                            :templateType="emailTemplate.type"
                            @on-editor-change="onEditorChange"
                          />
                          <div
                            class="align-right d-flex justify-content-end mt-3"
                          >
                            <button
                              type="button"
                              class="btn btn-info"
                              @click="preview"
                              :disabled="isSendingUpdate"
                            >
                              Preview &amp; Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="deliverables"
                :class="activeTab == 'deliverables' ? `active` : ``"
              >
                <ProjectDeliverables
                  v-if="project"
                  :deliverables="deliverables"
                  :originalDeliverablesString="null"
                  :project="project"
                  ref="project-deliverables"
                  readonly
                  is-brief
                />
              </div>
              <div
                class="tab-pane"
                id="csatfeedback"
                :class="activeTab == 'csatfeedback' ? `active` : ``"
              >
                <p>this is csatfeedback</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ActionConfirmModal
      v-if="isProjectArchiveOrUnarchiveClick"
      :title="projectArchiveModalProps.title"
      :text="projectArchiveModalProps.text"
      :submitText="projectArchiveModalProps.submitText"
      @close="isProjectArchiveOrUnarchiveClick = false"
      @submit="
        () => {
          archiveProject(true);
          isProjectArchiveOrUnarchiveClick = false;
        }
      "
    />
  </wrapper-page>
</template>

<script>
import WrapperPage from "../components/layout/WrapperPage.vue";
import ActionConfirmModal from "../components/ui/Modals/ActionConfirmModal.vue";
import {
  archiveProjectById,
  getDeliverableFiles,
  getProjectById,
  getProjectDeliverables,
  getProjectTimelineEvents,
  sendMultiUpdateRevisionPreview,
} from "@/apis/projects";
import { getAllWorkflows, getWorkflowById } from "@/apis/workflows";
import workflowMixin from "../mixins/workflow";
import eventBus, { channels } from "@/eventBus";
import TextEditor from "@/components/ui/TextEditor.vue";
import textEditorCompilerMixin from "@/mixins/textEditorCompiler";
import { getEmailTemplates } from "@/apis/email-templates";
import { getClientById } from "@/apis/clients";
import FormInputGroup from "@/components/ui/FormInputGroup.vue";
import ProjectMultiUpdatePreviewModal from "@/components/projects/ProjectMultiUpdatePreviewModal.vue";
import ProjectDeliverables from "@/components/projects/ProjectDeliverables.vue";

export default {
  components: {
    ProjectDeliverables,
    FormInputGroup,
    TextEditor,
    WrapperPage,
    ActionConfirmModal,
  },
  mixins: [workflowMixin, textEditorCompilerMixin],
  data() {
    return {
      loadingTimeline: true,
      activeTab: "previewSharing",
      project: {},
      projectId: this.$route.params.projectId,
      selectedWorkflowID: this.$route.params.workflowId,
      timeline: {},
      events: [],
      filteredEvents: [],
      deliverables: [],
      originalDeliverablesString: null, // Used to keep track of changes to the deliverables list
      isProjectArchiveOrUnarchiveClick: false,
      projectArchiveModalProps: {
        submitText: "Ok",
        title: "Are you sure you want to archive this project?",
        text: "This project will be hidden and can be found in the archived projects section.",
      },
      workflows: [],
      workflowIds: [],
      selectedWorkflow: {},
      multiDeliverables: [
        {
          workflowId: null,
          workflowTitle: null,
          stageId: null,
          stageTitle: null,
          moveForward: true,
          reviewLink: null,
        },
      ],
      placeholder: "Write here..",
      emailText: "",
      chosenTemplateIndex: 0,
      allEmailTemplates: [],
      emailTemplates: [],
      client: {},
      isSendingUpdate: false,
    };
  },
  computed: {
    workflowOptions() {
      return [
        { value: 0, text: "Select Deliverable" },
        ...this.workflowIds
          .map((w) => ({
            value: w.workflow_id,
            text: w.workflow_title,
          }))
          .filter((w) => {
            return this.stageOptions(w.value)?.length;
          }),
      ];
    },
    // gets the chosen email template in the client's language (falls back to the default language)
    emailTemplateInClientLanguage() {
      const clientLanguage = this.client?.language ?? "en";
      const chosenEmailTemplateId = this.emailTemplate.id;
      const templateVariants = this.allEmailTemplates.filter(
        (t) =>
          t.id === chosenEmailTemplateId ||
          t.parent_id === chosenEmailTemplateId
      );

      let template = templateVariants.find(
        (t) => t.language === clientLanguage
      );

      if (!template)
        // template in client language was not found, fall back on default language
        template = templateVariants.find((t) => t.language === "en");

      if (!template)
        // default to the parent template
        return this.emailTemplate;

      return template;
    },
    emailTemplate() {
      return this.emailTemplates[this.chosenTemplateIndex ?? 0];
    },
    canArchiveProjects() {
      return this.$store.getters.archivedProjectsRemaining !== 0;
    },
    isProjectCompleted() {
      return (
        this.getProductionProgress(
          this.timeline,
          this.project.is_survey_sent,
          this.project.send_csat_survey,
          this.project.survey_completed
        ) === 100
      );
    },
  },
  async created() {
    if (isNaN(Number(this.projectId))) {
      this.$router.push("/projects/");
      return;
    }

    this.loadData();
  },
  methods: {
    preview() {
      const modalOptions = {
        name: "project-multi-update-preview-modal",
        height: "auto",
      };
      this.$modal.show(
        ProjectMultiUpdatePreviewModal,
        {
          multiDeliverables: this.multiDeliverables,
          template: this.emailText,
          language: this.emailTemplate.language,
          project: this.project,
        },
        modalOptions,
        {
          "before-close": async (e) => {
            const toSend = e.params?.value?.action === "send";
            if (!toSend || this.isSendingUpdate) return;

            this.isSendingUpdate = true;

            const processedDeliverables = this.multiDeliverables.map((md) => {
              const workflow = this.workflows.find(
                (w) => w.id === md.workflowId
              );
              const stage = this.getStageByIdFromWorkflow(workflow, md.stageId);

              const currentRevision = this.getCurrentStageRevision(stage);

              if (!currentRevision?.id) return;

              return {
                workflowId: md.workflowId,
                revisionId: currentRevision.id,
                reviewLink: md.reviewLink,
                updateStatus: md.moveForward,
              };
            });

            sendMultiUpdateRevisionPreview(
              this.projectId,
              this.emailText,
              processedDeliverables
            )
              .then(() => {
                this.notifySuccess("Deliverable updates sent");
                this.isSendingUpdate = false;
                this.$router.push({
                  name: "project-timelines",
                  params: { projectId: this.projectId },
                });
              })
              .catch((error) => {
                this.notifyError(error, "Error updating deliverables");
                this.isSendingUpdate = false;
              });
          },
        }
      );
    },
    stageOptions(workflowId) {
      if (!workflowId) return [];

      const workflow = this.workflows?.find((w) => w.id == workflowId);
      const stages = this.getCurrentStages(workflow)?.filter(
        (s) => s.tasks?.length
      );

      if (!stages?.length) return [];

      return [
        { value: 0, text: "Select Stage" },
        ...stages?.map((task) => ({
          value: task.id,
          text: task.title,
        })),
      ];
    },
    onWorkflowSelect(workflowId, index) {
      const w = this.workflowIds.find((wIds) => wIds.workflow_id == workflowId);
      if (!w) return;

      const current = this.multiDeliverables[index];
      this.$set(this.multiDeliverables, index, {
        ...current,
        workflowId: w.workflow_id,
        workflowTitle: w.workflow_title,
        stageId: null,
        stageTitle: null,
      });
    },
    onStageSelect(stageId, index) {
      const current = this.multiDeliverables[index];
      const stageTitle = this.stageOptions(current.workflowId).find(
        (o) => o.value == stageId
      ).text;

      this.$set(this.multiDeliverables, index, {
        ...current,
        stageId: stageId,
        stageTitle,
      });
    },
    addNewDeliverable() {
      this.multiDeliverables.push({
        workflowId: null,
        stageId: null,
        stageTitle: null,
        moveForward: true,
        reviewLink: null,
      });
    },
    removeDeliverable(index) {
      if (this.multiDeliverables?.length <= 1) return;

      this.multiDeliverables.splice(index, 1);
    },
    onEditorChange(value) {
      this.emailText = this.replacePlaceholdersHelper(value);
    },
    updateChosenTemplateIndex() {
      // Filter and sort email templates
      this.allEmailTemplates = (this.$store.getters.emailTemplates || [])
        .filter((template) => template.type === "multi-update")
        .sort((a, b) => a.id - b.id);

      this.emailTemplates = this.allEmailTemplates.filter((t) => !t.parent_id);

      this.chosenTemplateIndex = 0;
      // Set email text based on chosen template
      if (this.chosenTemplateIndex !== -1) {
        this.emailText = this.replacePlaceholdersHelper(
          this.emailTemplateInClientLanguage.body,
          this.emailTemplateInClientLanguage.language
        );
      }
    },
    replacePlaceholdersHelper(text) {
      return this.replacePlaceholders(
        text,
        this.root,
        this.client,
        this.model,
        this.project
      );
    },
    async getAndUpdateProjectData(projectId) {
      const projectData = await getProjectById(projectId, true);
      this.workflowIds = projectData.workflow_ids;
      this.workflows = await getAllWorkflows(
        "id in (" +
          projectData.workflow_ids.map((w) => w.workflow_id).join() +
          ")"
      );
      this.events = await getProjectTimelineEvents(this.projectId);
      if (projectData.deliverable_type === "Multi") {
        this.filteredEvents = this.filterEventsByWrokflowId(
          this.events,
          this.selectedWorkflowID
            ? this.selectedWorkflowID
            : this.workflowIds[0].workflow_id
        );
      } else {
        this.filteredEvents = [...this.events];
      }
      const cancelProjectEvent = this.filteredEvents.find(
        (e) => e.category === "Cancel Project"
      );
      const sendPreviewEvents = this.filteredEvents.filter(
        (e) => e.category === "Send Preview"
      );
      const rejectEvent = this.filteredEvents.filter(
        (e) =>
          e.category === "Revision Rejected" || e.category === "Stage Rejected"
      );

      if (projectData && cancelProjectEvent) {
        projectData.cancelEventInfo = JSON.parse(cancelProjectEvent.event);
        projectData.cancelEventInfo.event_time = cancelProjectEvent.event_time;
      }
      if (projectData && sendPreviewEvents.length) {
        projectData.sendPreviewEvents = sendPreviewEvents.map(
          (previewEvent) => {
            const eventObj = JSON.parse(previewEvent.event);
            eventObj.event_time = previewEvent.event_time;
            eventObj.user_id = previewEvent.user_id;
            return eventObj;
          }
        );
      }
      if (projectData && rejectEvent.length) {
        projectData.rejectEvents = rejectEvent.map((rEvent) => {
          const eventObj = JSON.parse(rEvent.event);
          eventObj.event_time = rEvent.event_time;
          eventObj.user_id = rEvent.user_id;
          return eventObj;
        });
      }
      return projectData;
    },
    filterEventsByWrokflowId(events, targetWorkflowId) {
      if (!targetWorkflowId) return events;
      const filteredEvents = events.filter((event) => {
        const parsedEvent = JSON.parse(event.event);
        return parsedEvent.workflow_id === targetWorkflowId;
      });
      return filteredEvents;
    },
    async loadData() {
      try {
        this.loadingTimeline = true;
        this.project = await this.getAndUpdateProjectData(this.projectId);
        this.client = await getClientById(this.project.client_id);
        if (this.project.deliverable_type === "Multi") {
          if (!this.selectedWorkflowID) {
            this.selectedWorkflow = this.project.workflow_ids[0];
          } else {
            this.selectedWorkflow = this.project.workflow_ids.find(
              (workflowData) =>
                workflowData.workflow_id === this.selectedWorkflowID
            );
          }
          this.timeline = await getWorkflowById(
            this.selectedWorkflow?.workflow_id
          );
        } else {
          this.timeline = await getWorkflowById(this.project.workflow_id);
        }
        this.refreshAndLoadDeliverables();
      } catch (err) {
        this.notifyError(err, "Error on loading project data");
        if (this.project.id && !this.timeline.id) {
          this.$router.push("/projects/" + this.projectId);
        } else {
          this.$router.push("/projects/");
        }
        return;
      }

      try {
        // If the first stage doesn't have a started_on set, we set it as the project's start_date
        if (this.is_linear && !this.timeline.tasks[0].started_on) {
          this.timeline.tasks[0].started_on = this.project.start_date;
        }
      } catch (err) {
        this.notifyError(err, "Error on loading project deliverables");
      } finally {
        this.loadingTimeline = false;
      }
      if (window.location.hash === "#feedback") {
        this.activeTab = "feedback";
      }
    },
    async refreshAndLoadDeliverables() {
      const result = await getProjectDeliverables(this.projectId);
      if (result && result.length > 0) {
        this.deliverables = result
          .map((d) => {
            if (!d.files) d.files = [];

            return d;
          })
          .sort((a, b) => a?.last_save_time - b?.last_save_time);

        this.deliverables.forEach((deliverable, i) => {
          if (!this.projectId || !deliverable.id) return;

          getDeliverableFiles(this.projectId, deliverable.id)
            .then((res) => {
              const files = res.map((f) => {
                const storageEndpoint = process.env.VUE_APP_AZ_STORAGE_ENDPOINT;
                const url = `${storageEndpoint}${
                  storageEndpoint.at(-1) !== "/" ? "/" : ""
                }documents/${f.path}`;
                const filename = url.substring(url.lastIndexOf("/") + 1);

                let thumbnail = null;
                const type = `.${filename.split(".")[1]?.toLowerCase()}`;
                if (
                  [
                    ".jpg",
                    ".png",
                    ".svg",
                    ".gif",
                    ".jpeg",
                    ".bmp",
                    ".webm",
                    ".ogg",
                    ".mp4",
                    ".avi",
                    ".mkv",
                    ".wmv",
                    ".mov",
                  ].includes(type)
                ) {
                  thumbnail = url.replace(filename, `thumbnails/${filename}`);
                }

                return {
                  url,
                  size: f.size,
                  created_on: f.created_on,
                  thumbnail,
                  name: filename,
                  type,
                };
              });

              this.$set(this.deliverables, i, {
                ...deliverable,
                files,
                type: files.length ? "files" : "link",
              });
            })
            .catch((error) => {
              this.notifyError(error, "Error getting files for deliverable");
            });
        });
      } else if (this.$auth.user.isAgent) {
        this.deliverables = [{ title: null, body: null, files: [] }];
      }
    },
    async archiveProject(shouldArchive) {
      try {
        await archiveProjectById(this.projectId, shouldArchive);
        this.notifySuccess("Project Archived");
        this.$router.push("/projects/");
      } catch (err) {
        this.notifyError(err, "Error on archiving project");
      }
    },
  },
  watch: {
    client: function () {
      this.emailText = this.replacePlaceholdersHelper(
        this.emailTemplateInClientLanguage.body,
        this.emailTemplateInClientLanguage.language
      );
    },
    timeline() {
      this.project.workflow = this.timeline;
    },
    chosenTemplateIndex: function () {
      this.emailText = this.replacePlaceholdersHelper(
        this.emailTemplateInClientLanguage.body,
        this.emailTemplateInClientLanguage.language
      );
    },
    emailText: function () {
      this.emailText = this.replacePlaceholdersHelper(
        this.emailText,
        this.emailTemplateInClientLanguage.language
      );
    },
    "$store.getters.emailTemplates": {
      handler() {
        this.updateChosenTemplateIndex();
      },
      immediate: true, // Trigger handler immediately on component mount
    },
  },
  async mounted() {
    let templates = await getEmailTemplates().then((data) => {
      return data.sort((a, b) => a.id - b.id);
    });

    templates = templates.filter(function (template) {
      return template.type === "multi-update";
    });
    this.emailTemplates = templates;
    const multiUpdateTemplateIndex = this.emailTemplates.findIndex(
      (t) => t.type === "multi-update"
    );

    if (multiUpdateTemplateIndex >= 0)
      this.chosenTemplateIndex = multiUpdateTemplateIndex;

    this.emailText = this.replacePlaceholdersHelper(
      this.emailTemplates[this.chosenTemplateIndex].body
    );

    eventBus.$on(channels.fetchProject, async () => {
      this.project = await this.getAndUpdateProjectData(this.projectId);
      this.client = await getClientById(this.project.client_id);
    });
    eventBus.$on(channels.refreshProject, async () => {
      this.loadData();
    });
    eventBus.$on(channels.fetchTimeline, async () => {
      this.timeline = await getWorkflowById(this.project.workflow_id);
    });
  },
  beforeDestroy() {
    eventBus.$off(channels.fetchProject);
    eventBus.$off(channels.refreshProject);
    eventBus.$off(channels.fetchTimeline);
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #16a1b8;
  border-color: #16a1b8;
}

.custom-plus-button {
  padding: 0rem 0.3rem;
  height: 1.2rem;
  font-size: 0.8rem;
  margin: 0 0.5rem;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  padding: 0.5rem;
  text-align: center;
}
</style>
